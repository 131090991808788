// to top



.to-top-wrapper {
	position: fixed;
	right: 0;
	bottom: 0;
	height: 0;
	overflow: hidden;
	opacity: 0;
	transition: opacity 0.4s ease-in-out;

	&.show {
		height: auto;
		opacity: 1;
	}

	> .btn {
		margin: 0.5em;
	}
}

// resolve collisions with footer content
@include createSpacerForToTop();