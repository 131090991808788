// text


// TEXT SHADOW

.text-shadow-dark {
	text-shadow: $text-shadow-dark;
}
.text-shadow-darker {
	text-shadow: $text-shadow-darker;
}
.text-shadow-darkest {
	text-shadow: $text-shadow-darkest;
}