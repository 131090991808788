// footer


.page-footer {
	margin-top: $footer-margin-top;
	padding-bottom: 1.5em;
	background-color: $mystyle-footer-bg-color;
	box-shadow: 0 2500px 0 2500px $mystyle-footer-bg-color;
	
	a[href]:not([class*="color-"]) {
		color: inherit;
	}
}

.footer-logo {
	filter: grayscale(100%) opacity(67%);
	transition: $transition-standard;

	&:hover {
		filter: grayscale(0%) opacity(100%);
	}
}