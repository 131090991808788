// mystyle badge


.badge-pill-sm {
	padding-left: 0.5em;
	padding-right: 0.5em;
	font-weight: normal;
}

.badge-overlay {
	position: absolute;
	left: 50%;
	top: 0.5em;
}