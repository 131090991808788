// breadcrumb



.main-breadcrumb {
	list-style: none;
	margin-top: 0;
	margin-bottom: 0.5rem;
	padding-left: 0;
	font-size: 0.85rem;
	@include clearfix()

	a {
		color: $breadcrumb-link-color;
	}
}

.breadcrumb-item + .breadcrumb-item::before {
	padding-right: 0;
}

.breadcrumb-fixed {
	
}